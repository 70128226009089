import React, { Component } from "react";
import { Link } from "gatsby";
import "./Footer.scss";
import config from "../../../data/SiteConfig";
import SocialLinks from "../SocialLinks/SocialLinks";

export default class Footer extends Component {
  render() {
    const {
      location: { pathname },
      toggleModal
    } = this.props;
    const { copyright, siteLogoWhite } = config;

    return (
      <footer className="section has-background-black-ter">
        <div className="container has-text-centered">
          <div>
            <Link to="/">
              <img
                src={siteLogoWhite}
                alt={config.siteTitle}
                className="footer-logo"
              />
            </Link>
          </div>

          <div className="footer-nav-list">
            <Link to="/privacy-policy/" className="footer-nav-item link">
              Privacy Policy
            </Link>
            <Link to="/affiliate-disclosure/" className="footer-nav-item link">
              Disclosure
            </Link>
          </div>

          <div className="footer-nav-list">
            <SocialLinks linkClassName="footer-nav-item" />
          </div>

          <button
            onClick={() => toggleModal(`${pathname}@footer`)}
            className="footer-cta button is-primary has-text-weight-bold is-uppercase"
            type="button"
          >
            Subscribe to our newsletter
          </button>

          <div className="is-size-7 has-text-grey">{copyright}</div>
        </div>
      </footer>
    );
  }
}
