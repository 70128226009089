import React from "react";
import Helmet from "react-helmet";
import config from "../../data/SiteConfig";
import Footer from "../components/Footer/Footer";
import Navbar from "../components/Navbar/Navbar";
import Modal from "../components/Modal/Modal";
import "./index.scss";

export default class MainLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = { showModal: false, signupLocation: "" };
    this.toggleModal = this.toggleModal.bind(this);
  }

  toggleModal(signupLocation) {
    const { showModal } = this.state;
    this.setState({ showModal: !showModal, signupLocation });
  }

  render() {
    const { showModal, signupLocation } = this.state;
    const { children, location } = this.props;
    return (
      <div>
        <Helmet>
          <meta name="description" content={config.siteDescription} />
          <meta name="webgains-site-verification" content="sz5mtqsi" />
          <meta httpEquiv="content-language" content="en-us" />
        </Helmet>
        <Navbar location={location} toggleModal={this.toggleModal} />
        <main>{children}</main>
        <Footer location={location} toggleModal={this.toggleModal} />
        <Modal
          isVisible={showModal}
          toggleModal={this.toggleModal}
          content="Get notified of discounts and good deals"
          buttonText="Subscribe To Our Newsletter"
          signupLocation={signupLocation}
        />
      </div>
    );
  }
}
