import React from "react";
import "./Modal.scss";
import EmailForm from "../Form/EmailForm";
import config from "../../../data/SiteConfig";

export default class Modal extends React.Component {
  constructor(props) {
    super(props);
    this.state = { modalClass: "modal" };
    this.handleClick = this.handleClick.bind(this);
  }

  // Setting timeout for fading animation
  componentDidUpdate(prevProps) {
    const { isVisible } = this.props;

    if (!prevProps.isVisible && isVisible) {
      this.setState({ modalClass: "modal is-active fadeIn" });

      setTimeout(() => {
        this.setState({ modalClass: "modal is-active" });
      }, 300);
    } else if (prevProps.isVisible && !isVisible) {
      this.setState({ modalClass: "modal is-active fadeOut" });

      setTimeout(() => {
        this.setState({ modalClass: "modal" });
      }, 300);
    }
  }

  handleClick() {
    const { toggleModal } = this.props;
    toggleModal();
  }

  render() {
    // Image = Lead Magnet Image
    // signupLocation: pathname@location, e.g. /best-jackets@navbar
    const { buttonText, content, image, signupLocation } = this.props;
    const { modalClass } = this.state;

    return (
      <div className={modalClass}>
        <div
          className="modal-background"
          role="none"
          onClick={this.handleClick}
        />
        <div className="modal-card">
          <header className="modal-card-head">
            <div className="modal-card-title">
              <progress
                className="progress is-info is-small"
                value="90"
                max="100"
              >
                90%
              </progress>
              <h6 className="subtitle is-7 has-text-centered">
                Almost there! Only one last step to take.
              </h6>
            </div>
          </header>
          <section className="modal-card-body">
            <div className="columns is-centered">
              {image ? (
                <div className="column is-4 has-text-centered">
                  {/* alt: Split the file path, take the last bit (filename), cut out the extension */}
                  <img
                    className="modal-image"
                    alt={
                      image
                        .split("/")
                        .slice(-1)[0]
                        .split(".")[0]
                    }
                    src={image}
                  />
                </div>
              ) : null}
              <div className="column is-8 has-text-centered">
                <h2 className="title is-size-4 has-text-weight-bold">
                  {content}
                </h2>
                <br />
                <EmailForm
                  hiddenFieldName={config.mailchimpHiddenField}
                  submitURL={config.mailchimpOptInURL}
                  signupLocation={signupLocation}
                  buttonText={buttonText}
                />
              </div>
            </div>
          </section>
        </div>

        <button
          className="modal-close is-large"
          aria-label="close"
          type="button"
          onClick={this.handleClick}
        />
      </div>
    );
  }
}
