import React from "react";
import { Link } from "gatsby";
import { IconContext } from "react-icons";
import {
  IoLogoInstagram,
  IoLogoFacebook,
  IoLogoPinterest,
  IoLogoTwitter,
  IoLogoRss
} from "react-icons/io";
import config from "../../../data/SiteConfig";

export default class SocialLinks extends React.Component {
  render() {
    const { linkClassName } = this.props;
    return (
      <IconContext.Provider value={{ size: "1.5em" }}>
        <a
          className={linkClassName}
          target="_blank"
          rel="noopener noreferrer"
          href={`https://www.instagram.com/${config.siteInstagramID}`}
        >
          <IoLogoInstagram />
        </a>
        <a
          className={linkClassName}
          target="_blank"
          rel="noopener noreferrer"
          href={`https://www.facebook.com/${config.siteFBID}`}
        >
          <IoLogoFacebook />
        </a>
        <a
          className={linkClassName}
          target="_blank"
          rel="noopener noreferrer"
          href={`https://www.pinterest.com/${config.sitePinterestID}`}
        >
          <IoLogoPinterest />
        </a>
        <a
          className={linkClassName}
          target="_blank"
          rel="noopener noreferrer"
          href={`https://www.twitter.com/${config.siteTwitterID}`}
        >
          <IoLogoTwitter />
        </a>
        <Link className={linkClassName} to={config.siteRss}>
          <IoLogoRss />
        </Link>
      </IconContext.Provider>
    );
  }
}
