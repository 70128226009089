module.exports = {
  siteTitle: "Mr.Alife", // Site title.
  siteTitleShort: "Mr.Alife", // Short site title for homescreen (PWA). Preferably should be under 12 characters to prevent truncation.
  siteTitleAlt: "Mr.Alife", // Alternative site title for SEO.
  siteLogo: "/logos/logo_black.png", // Logo used for SEO and manifest.
  siteLogoAMP: "/logos/logo_black_60h.png", // AMP Logo with 60px height according to Google guidelines. Used for SEO.
  siteLogoWhite: "/logos/logo_white.png", // Logo used for Footer.
  siteLogoSVG: "/logos/logo_black.svg", // Logo used for navbar.
  siteUrl: "https://www.mralife.com", // Domain of your website without pathPrefix.
  siteDescription: "Style Tips & Fashion News For Men", // Website description used for RSS feeds/meta description tag.
  siteRss: "/rss.xml", // Path to the RSS file.
  // siteFBAppID: "1825356251115265", // FB Application ID for using app insights
  siteFBID: "misteralife", // renders FB links in navbar and SEO
  siteFBAdminID: "100017558772080", // FB admin user ID
  siteFBPageID: "176275633263757", // fb:page_id for SEO
  siteTwitterID: "misteralife", // renders Twitter links in navbar and SEO
  sitePinterestID: "misteralife", // renders Pinterest link in navbar and SEO
  siteInstagramID: "misteralife", // renders Instagram link in navbar and SEO
  disqusShortname: "mr-alife",
  mailchimpOptInURL:
    "https://mralife.us19.list-manage.com/subscribe/post?u=f33bd4953b2aba9a67bcf922e&amp;id=21d1019646", // MailChimp subscription list URL for email collectiom form
  mailchimpHiddenField: "b_f33bd4953b2aba9a67bcf922e_21d1019646", // Hidden field name in MailChimp opt-in form to prevent bot signups
  // leadMagnetImg: "/assets/boost-your-airbnb-income-in-1-hour.png", // Lead Magnet image to be used in opt-in form
  googleAnalyticsID: "UA-128245363-1", // GA tracking ID.
  postDefaultCategoryID: "Fashion", // Default category for posts.
  dateFromFormat: "YYYY-MM-DD", // Date format used in the frontmatter.
  dateFormat: "MMMM DD, YYYY", // Date format for display.
  authorName: "William Shea", // use for SEO tag of post and gatsby-config rssMetadata
  copyright: "© 2018 Mr.Alife", // Copyright string for the footer of the website and RSS feed.
  themeColor: "#c62828", // Used for setting manifest and progress theme colors.
  backgroundColor: "#e0e0e0" // Used for setting manifest background color.
};
