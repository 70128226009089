import React, { Component } from "react";

export default class EmailForm extends Component {
  render() {
    const {
      buttonText,
      hiddenFieldName,
      submitURL,
      signupLocation
    } = this.props;

    return (
      <form action={submitURL} method="post">
        <div className="field">
          <div className="field">
            <input type="hidden" name="SIGNUP" value={signupLocation || ""} />
            <div className="control">
              <input
                type="email"
                className="input is-medium"
                id="email"
                name="EMAIL"
                placeholder="Enter your Email Address"
                required
              />
              <p className="help">
                Spam-free! You can unsubscribe at any time if you wish.
              </p>
            </div>
            <div
              style={{ position: "absolute", left: -5000 }}
              aria-hidden="true"
            >
              <input
                type="text"
                name={hiddenFieldName}
                tabIndex="-1"
                defaultValue=""
              />
            </div>
          </div>
          <br />
          <div className="field">
            <div className="control">
              <button
                type="submit"
                className="button is-fullwidth is-medium is-primary has-text-weight-bold"
              >
                {buttonText}
              </button>
            </div>
          </div>
        </div>
      </form>
    );
  }
}
