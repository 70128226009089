import React from "react";
import { Link } from "gatsby";
import classnames from "classnames";
import SocialLinks from "../SocialLinks/SocialLinks";
import config from "../../../data/SiteConfig";

export default class Navbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = { showMobileMenu: false };
    this.toggleMenu = this.toggleMenu.bind(this);
  }

  toggleMenu() {
    const { showMobileMenu } = this.state;
    this.setState({ showMobileMenu: !showMobileMenu });
  }

  render() {
    const {
      location: { pathname },
      toggleModal
    } = this.props;
    const { showMobileMenu } = this.state;

    return (
      <header>
        <nav className="navbar is-fixed-top has-shadow">
          <div className="container">
            <div className="navbar-brand">
              <Link to="/" className="navbar-item">
                <figure className="image">
                  <img
                    src={config.siteLogoSVG}
                    alt={config.siteTitle}
                    style={{ width: "110px" }}
                  />
                </figure>
              </Link>
              <span
                className={classnames("navbar-burger burger", {
                  "is-active": showMobileMenu
                })}
                role="button"
                tabIndex={0}
                onClick={this.toggleMenu}
              >
                <span />
                <span />
                <span />
              </span>
            </div>
            <div
              className={classnames("navbar-menu", {
                "is-active": showMobileMenu
              })}
            >
              <div className="navbar-end">
                <Link
                  className="navbar-item is-uppercase is-size-7 has-text-weight-bold"
                  to="/category/fashion"
                >
                  Fashion
                </Link>
                <a
                  className="navbar-item is-uppercase is-size-7 has-text-weight-bold has-text-danger"
                  onClick={() => toggleModal(`${pathname}@navbar`)}
                >
                  Newsletter
                </a>
                <SocialLinks linkClassName="navbar-item" />
              </div>
            </div>
          </div>
        </nav>
      </header>
    );
  }
}
